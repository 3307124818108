import { ColorizeSetting } from '@src/types/layout'

// enum - datagrid
export enum DATAGRID_TYPE {
  LEADERBOARD = 0,
  AFFINITY
}

export enum RemoveState {
  Enable = 0,
  Disable,
  Hidden
}

export enum SkillElements {
  md = 'md',
  pd = 'pd',
  pt = 'pt',
  td = 'td',
  kw = 'kw',
  b = 'b',
  db = 'db',
  fd = 'fd'
}

export enum SkillAttributes {
  multipler = 'multipler'
}

// const - animation
export const DEFAULT_ANIMATE_DURATION = 400

// const - message
export const DEFAULT_MESSAGE_SHOW_DURATION = 3000

// const - input filter
export const INPUT_PROPS = {
  PLANET_SELLING_PRICE: {
    pattern: '[0-9]{1,10}([.][0-9]+)?',
    maxLength: 10
  },
  PLANET_SELLING_DURATION: { pattern: '[0-9]{1,3}([.][0-9]+)?', maxLength: 3 }
}

export const INPUT_DEBOUNCE = 1000

// const - third party nft paging
export const DEFAULT_THIRD_PARTY_NFT_PAGE_INFO = {
  total: 0,
  nextCursor: ''
}

/**
 * const - skill text style
 * @see https://github.com/FoonieMagus/ApeironTools/tree/main/GDSheetTools/howto#how-to-use-highlight-tags
 */
export const SkillColorizeMapping: Record<SkillElements, ColorizeSetting> = {
  [SkillElements.md]: {
    color: '#2a9dff',
    hover: false,
    underline: false,
    weight: 300
  },
  [SkillElements.pd]: {
    color: '#ff2a2a',
    hover: false,
    underline: false,
    weight: 300
  },
  [SkillElements.pt]: {
    hover: false,
    underline: false,
    weight: 400
  },
  [SkillElements.td]: {
    color: '#ff82a8',
    hover: false,
    underline: false,
    weight: 300
  },
  [SkillElements.kw]: {
    hover: true,
    underline: true,
    weight: 500
  },
  [SkillElements.b]: {
    color: '#00A65C',
    hover: false,
    underline: false,
    weight: 300
  },
  [SkillElements.db]: {
    color: '#ff9600',
    hover: true,
    underline: false,
    weight: 300
  },
  [SkillElements.fd]: {
    hover: false,
    underline: false,
    weight: 300
  }
}

export const NAV_BAR_CONFIG = {
  primary: {
    height: 56
  },
  secondary: {
    height: 56,
    borderWidth: 3
  },
  tertiary: {
    height: 60
  }
} as const
