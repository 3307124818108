import { FeatureFlagMap } from '@apeiron/temp'

// ? naming start by "Use" or "Show"
export enum FeatureFlagType {
  // login
  ShowLoginGettingStarted = 0,

  // account
  ShowAccountSSO,
  ShowAccountQRCode,

  // battle demo
  ShowBattleDemoNews,
  ShowBattleDemoReleaseNote,

  // reward
  ShowRewardShop,
  ShowRewardMakeupCheckInByAnima,

  // apostle
  ShowApostleSpiritualMerge,

  // planet
  ShowPlanetHistory,
  ShowPlanetRelic,
  ShowPlanetStat,

  // trade (buy / sell)
  ShowTradeRemark,

  // rental
  ShowRentalSuggestedFee,

  // download
  ShowDownloadQRCode,

  // lootrush
  ShowLootRush
}

export const FEATURE_FLAG_MAP: FeatureFlagMap<FeatureFlagType> = {
  // login
  [FeatureFlagType.ShowLoginGettingStarted]: [],

  // account
  [FeatureFlagType.ShowAccountSSO]: [],
  [FeatureFlagType.ShowAccountQRCode]: [],

  // battle demo
  [FeatureFlagType.ShowBattleDemoNews]: ['development', 'alpha', 'staging'],
  [FeatureFlagType.ShowBattleDemoReleaseNote]: [],

  // reward
  [FeatureFlagType.ShowRewardShop]: [],
  [FeatureFlagType.ShowRewardMakeupCheckInByAnima]: [
    'development',
    'alpha',
    'staging'
  ],

  // apostle
  [FeatureFlagType.ShowApostleSpiritualMerge]: [
    'development',
    'alpha',
    'staging'
  ],

  // planet
  [FeatureFlagType.ShowPlanetHistory]: [],
  [FeatureFlagType.ShowPlanetRelic]: [],
  [FeatureFlagType.ShowPlanetStat]: [],

  // trade (buy / sell)
  [FeatureFlagType.ShowTradeRemark]: [],

  // rental
  [FeatureFlagType.ShowRentalSuggestedFee]: [],

  // download
  [FeatureFlagType.ShowDownloadQRCode]: [],

  // lootrush
  [FeatureFlagType.ShowLootRush]: ['development', 'alpha', 'staging']
}
