// ? this enum align with backend
export enum NotificationDataType {
  DailyCheckIn = 'DailyCheckIn',
  Expedition = 'Expedition'
}

export enum NotifyType {
  // ! don't move this, this is top bar position
  TOPBAR_GAME = 0,
  TOPBAR_EMPORIUM,
  TOPBAR_STAKE,
  TOPBAR_SANCTUARY,
  TOPBAR_GODIVERSE,

  // ? other place badge place at bottom
  SHORTCUT_DAILY_CHECKIN
}
