import {
  ApeironLinkButton,
  DEFAULT_ANIMATE_DURATION,
  NextImage
} from '@apeiron/temp'
import { styled } from '@mui/material'
import CheckInPNG from '@public/icons/icon-daily-check-in.png'
import CheckInHighlightPNG from '@public/icons/icon-daily-check-in-highlight.png'
import MuiBadge from '@src/components/share/mui/Badge'
import { NotifyType } from '@src/constants/notification'
import { ROUTE } from '@src/constants/route'
import { useNotificationContext } from '@src/contexts/share/NotificationContext'
import * as R from 'ramda'
import { FC } from 'react'

const Button = styled(ApeironLinkButton)`
  aspect-ratio: 1;
  border-radius: unset;
  height: 44px;
  min-width: unset;
  padding: 6px;

  :hover {
    background-color: #555c7e;
    transition: ${DEFAULT_ANIMATE_DURATION}ms;
  }
`

const StyledMuiBadge = styled(MuiBadge)`
  & > .MuiBadge-badge {
    background-color: red;
    color: white;
  }
  left: 8px;
  position: absolute;
  top: 14px;
`

const CheckInIcon = styled(NextImage)`
  aspect-ratio: 1;
  width: 100%;
`

const ShortcutDailyCheckInButton: FC<Props> = (props: Props) => {
  const { className } = props

  const { getNotifyMessage } = useNotificationContext()

  const notifyMessage = getNotifyMessage(NotifyType.SHORTCUT_DAILY_CHECKIN)

  const checkInIcon = R.isNotNil(notifyMessage)
    ? CheckInHighlightPNG
    : CheckInPNG

  return (
    <Button className={className} url={ROUTE.REWARD_DAILY_CHECK_IN}>
      <CheckInIcon src={checkInIcon} />
      <StyledMuiBadge type={NotifyType.SHORTCUT_DAILY_CHECKIN} />
    </Button>
  )
}

type Props = {
  className?: string
}

export default ShortcutDailyCheckInButton
